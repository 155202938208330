<template>
	<div class="container">
		<div class="body-contents gap64 fill-out">
			<div class="section">
				<h2>회사법인정보관리</h2>
				<h3 class="title">회사 법인 정보</h3>
				<div class="contents group column gap20">
					<!-- 사업자 구분(제거) -->
					<!-- 상호 법인명 -->
					<div class="group row col1 half">
						<div class="group-inp" :class="companyInfoValidClass.corporateName">
							<label for="" class="label asterisk">상호(법인명)</label>
							<input
								id="corporateName"
								type="text"
								placeholder="상호명을 입력해주세요."
								v-model.trim="companyInfo.corporateName"
								maxlength="64"
								@input="validateCorporateName"
								:disabled="companyInfo.corporateName"
							/>
							<p class="text-detail invalid" v-if="companyInfoValidClass.corporateName === 'invalid'">
								<i class="ic-16 ic-invalid"></i>
								{{ requiredMessage(companyInfo.corporateName, '상호(법인)명 64자 이하로 입력해주세요.') }}
							</p>
						</div>
					</div>
					<!-- 대표 번호 & 대표자 명 -->
					<div class="group row col2 gap40 align-start">
						<!-- 대표자 명 -->
						<div class="group-inp" :class="companyInfoValidClass.ceoName">
							<label for="" class="label asterisk"> 대표자명 </label>
							<input
								id="ceoName"
								type="text"
								v-model="companyInfo.ceoName"
								maxlength="명"
								@input="validateCeoName"
								disabled
							/>
							<p class="text-detail invalid" v-if="companyInfoValidClass.ceoName === 'invalid'">
								<i class="ic-16 ic-invalid"></i>
								{{ requiredMessage(companyInfo.ceoName, '대표자명은 2글자 이상, 100글자 이하로 입력해주세요.') }}
							</p>
						</div>

						<div class="group-inp" :class="companyInfoValidClass.repNumber">
							<label for="" class="label"> 대표번호 </label>
							<input
								id="repNumber"
								type="text"
								placeholder="대표 번호를 입력해 주세요."
								v-model="companyInfo.repNumber"
								@input="validateRepNumber"
								maxlength="40"
							/>
							<p class="text-detail invalid" v-if="companyInfoValidClass.repNumber === 'invalid'">
								<i class="ic-16 ic-invalid"></i>
								{{ requiredMessage(companyInfo.repNumber, '대표번호는 40자 이하, 숫자로 입력해주세요.') }}
							</p>
						</div>
					</div>
					<!--	개업일자 : 사용안함	-->
					<!--						<div class="group row col1 half">-->
					<!--							<div class="group-inp">-->
					<!--								<label for="" class="label asterisk">개업일자</label>-->
					<!--								<PortalCalendar></PortalCalendar>-->
					<!--							</div>-->
					<!--						</div>-->
					<!-- 사업자등록번호 -->
					<div class="group row col1 half">
						<div class="group-inp">
							<label for="" class="label asterisk">
								사업자등록번호
								<i class="ic-16 ic-info">
									<div class="tooltip-layer left">
										사업자등록번호, 개업일자, 대표자성명은 사업자 진위 확인 필수값입니다. 반드시 입력 후 진위 확인을
										하세요.
									</div>
								</i>
							</label>
							<div class="group-inp-btn" :class="companyInfoValidClass.businessLicenseNum" id="testButtonInput">
								<input
									id="businessLicenseNum"
									type="text"
									placeholder="0000000000"
									maxlength="10"
									v-model.trim="inputBusinessLicenseNum"
									@input="validateBusinessLicenseNumber"
									:disabled="isConfirmedLicense === 'valid'"
								/>
							</div>
							<p class="text-detail invalid" v-if="companyInfoValidClass.businessLicenseNum === 'invalid'">
								<i class="ic-16 ic-invalid"></i>
								{{ requiredMessage(inputBusinessLicenseNum, '사업자 등록번호 형식으로 입력해주세요.') }}
							</p>
							<p class="text-detail valid" v-if="isConfirmedLicense === 'valid'">
								<i class="ic-16 ic-valid"></i>
								사업자 진위 확인이 완료되었습니다.
							</p>
						</div>
					</div>
					<!-- 사업자 주소 (현재 1줄 처리 - 주재민)-->
					<div class="group row col1 full">
						<div class="group-inp">
							<label for="" class="label asterisk">사업장 주소</label>
							<div class="group row col1 full">
								<!-- <div class="group-inp"><label for="" class="label blind"> 우편번호 </label><input type="text" id="" class="" placeholder="우편번호 자동" disabled /></div> -->
								<div class="group-inp" :class="companyInfoValidClass.companyAddress">
									<label for="" class="label blind"> 주소 </label>
									<input
										id="companyAddress"
										type="text"
										maxlength="500"
										v-model.trim="companyInfo.companyAddress"
										placeholder="기본 주소"
										@input="validateCompanyAddress"
									/>
									<p class="text-detail invalid" v-if="companyInfoValidClass.companyAddress === 'invalid'">
										<i class="ic-16 ic-invalid"></i>
										{{
											requiredMessage(
												companyInfo.companyAddress,
												'사업자 주소는 2글자 이상, 500글자 이하로 입력해주세요.',
											)
										}}
									</p>
								</div>
								<!--									<div class="group-inp">-->
								<!--										<label for="" class="label blind"> 상세 주소 </label>-->
								<!--										<div class="group-inp-btn" id="testButtonInput">-->
								<!--											<input type="text" id="testText09" class="" placeholder="상세주소를 입력해주세요." /><button-->
								<!--												class="line"-->
								<!--												@click="searchAddress"-->
								<!--											>-->
								<!--												주소 검색-->
								<!--											</button>-->
								<!--										</div>-->
								<!--									</div>-->
							</div>
						</div>
					</div>
					<!-- 법인번호 & 산업분류 -->
					<div class="group row col2 gap40 align-start">
						<div class="group-inp" :class="companyInfoValidClass.businessNum">
							<label for="" class="label">법인번호</label>
							<input
								id="businessNum"
								type="text"
								placeholder="숫자 13자리를 입력해 주세요."
								v-model="companyInfo.businessNum"
								maxlength="50"
								@input="validateBusinessNum"
							/>
							<p class="text-detail invalid" v-if="companyInfoValidClass.businessNum === 'invalid'">
								<i class="ic-16" :class="[checkInteger(companyInfo.businessNum) ? 'ic-valid' : 'ic-invalid']"></i>
								법인번호는 50글자 이하, 숫자로 입력해주세요.
							</p>
						</div>
						<div class="group-inp">
							<label for="" class="label">산업분류</label>
							<div class="select normal">
								<select v-model="companyInfo.industryCategory">
									<option value="" selected="" disabled="">산업분류를 선택해 주세요.</option>
									<option v-for="item in $constants.INDUSTRY_CATEGORY" :key="item.id" :value="item.name">
										{{ item.name }}
									</option>
								</select>
							</div>
						</div>
					</div>
					<!-- 업태 & 종목 -->
					<div class="group row col2 gap40 align-start">
						<!-- 업태 -->
						<div class="group-inp" :class="companyInfoValidClass.businessCondition">
							<label for="" class="label asterisk">업태</label>
							<input
								id="businessCondition"
								type="text"
								maxlength="120"
								placeholder="업태를 입력해주세요"
								v-model.trim="companyInfo.businessCondition"
								@input="validateBusinessCondition"
							/>
							<p class="text-detail invalid" v-if="companyInfoValidClass.businessCondition === 'invalid'">
								<i class="ic-16 ic-invalid"></i>
								{{ requiredMessage(companyInfo.businessCondition, '업태는 1글자 이상, 120글자 이하로 입력해주세요.') }}
							</p>
						</div>
						<div class="group-inp" :class="companyInfoValidClass.businessType">
							<label for="" class="label asterisk">종목</label>
							<input
								id="businessType"
								type="text"
								maxlength="120"
								placeholder="종목을 입력해 주세요"
								v-model.trim="companyInfo.businessType"
								@input="validateBusinessType"
							/>
							<p class="text-detail invalid" v-if="companyInfoValidClass.businessType === 'invalid'">
								<i class="ic-16 ic-invalid"></i>
								{{ requiredMessage(companyInfo.businessType, '종목은 1글자 이상, 120글자 이하로 입력해주세요.') }}
							</p>
						</div>
					</div>
					<div class="group row col1 half">
						<div class="group-inp" :class="companyInfoValidClass.employeeCount">
							<label for="" class="label"> 실직원수 </label>
							<input
								id="employeeCount"
								type="text"
								v-model.trim="companyInfo.employeeCount"
								@input="validateEmployeeCount"
							/>
							<p class="text-detail invalid" v-if="companyInfoValidClass.employeeCount === 'invalid'">
								<i class="ic-16 ic-invalid"></i>
								숫자를 입력해주세요.
							</p>
						</div>
					</div>
					<div class="group column">
						<div class="group-inp group column auto">
							<label for="" class="label asterisk">사업자 등록증</label>
						</div>
						<Attach @input="uploadedAttaches" :attachBtnName="'파일 선택'" :id="'businessLicenses'"></Attach>
						<p class="text-detail invalid" v-if="companyInfoValidClass.businessLicenses === 'invalid'">
							<i class="ic-16 ic-invalid"></i>
							사업자 등록증을 첨부해주세요.
						</p>
					</div>
				</div>
			</div>
			<div class="section">
				<h3 class="title">세금계산서 담당자 정보</h3>
				<div class="contents group column gap20">
					<div class="group row col1 full">
						<div class="group-inp" :class="companyInfoValidClass.taxInvoiceManagerName">
							<label for="" class="label asterisk">담당자명</label>
							<input
								id="taxInvoiceManagerName"
								type="text"
								v-model="companyInfo.taxInvoiceManager.taxInvoiceManagerName"
								@input="validateTaxManagerName"
							/>
							<p class="text-detail invalid" v-if="companyInfoValidClass.taxInvoiceManagerName === 'invalid'">
								<i class="ic-16 ic-invalid"></i>
								{{
									requiredMessage(
										companyInfo.taxInvoiceManager.taxInvoiceManagerName,
										'담당자명은 1글자 이상, 100글자 이하로 입력해주세요.',
									)
								}}
							</p>
						</div>
					</div>
					<!-- 담당자 이메일 -->
					<div class="group row col1 full">
						<div class="group-inp" :class="companyInfoValidClass.taxInvoiceEmail">
							<label for="" class="label asterisk"> 담당자 이메일 </label>
							<input
								id="taxInvoiceEmail"
								type="text"
								v-model="companyInfo.taxInvoiceManager.taxInvoiceEmail"
								placeholder="대표 이메일 입력해 주세요."
								@input="validateTaxEmail"
							/>
							<p class="text-detail invalid" v-if="companyInfoValidClass.taxInvoiceEmail === 'invalid'">
								<i class="ic-16 ic-invalid"></i>
								{{ requiredMessage(companyInfo.taxInvoiceManager.taxInvoiceEmail, '올바른 이메일 형식이 아닙니다.') }}
							</p>
						</div>
					</div>
					<!-- 개인정보 처리방침 확인 -->
					<div class="group row col1 full">
						<div class="group-inp">
							<div class="group-chk">
								<input
									type="checkbox"
									id="checkedPrivacyPolicy"
									v-model="checkedPrivacyPolicy"
									:disabled="hasCompanyProperty"
								/>
								<label for="checkedPrivacyPolicy" class="label"
									>(필수) ‘개인정보처리방침’은 회사와 이용계약을 체결한 회원에 한해 적용되며, 회원의 관리하에 운영되는
									서비스(다우오피스, 다우오피스 고객포털)를 통해 취급되는 정보주체에게는 적용되지 않습니다.</label
								>
							</div>
							<p class="text-detail invalid" v-if="companyInfoValidClass.checkedPrivacyPolicy === 'invalid'">
								<i class="ic-16 ic-invalid"></i>
								개인정보처리방침에 동의해주세요.
							</p>
						</div>
					</div>
				</div>
			</div>
			<div class="section">
				<h3 class="title">환불계좌</h3>
				<div class="contents group column gap20">
					<!-- 계좌번호 금융기관명 -->
					<div class="group row col2 gap40 align-start">
						<!-- 계좌번호 -->
						<div class="group-inp" :class="companyInfoValidClass.refundAccountNumber">
							<label for="" class="label"> 계좌번호 </label>
							<input
								id="refundAccountNumber"
								type="text"
								v-model="companyInfo.taxInvoiceManager.refundAccountNumber"
								placeholder="계좌번호를 입력해주세요."
								@input="validateAccountNumber"
							/>
							<p class="text-detail invalid" v-if="companyInfoValidClass.refundAccountNumber === 'invalid'">
								<i class="ic-16 ic-invalid"></i>
								숫자로 입력해주세요.
							</p>
						</div>
						<!-- 금융기관명 -->
						<div class="group-inp" :class="companyInfoValidClass.bankName">
							<label for="" class="label"> 금융기관명 </label>
							<input
								id="bankName"
								type="text"
								v-model="companyInfo.taxInvoiceManager.bankName"
								placeholder="금융기관명을 입력해주세요."
								@input="validateBankName"
							/>
						</div>
					</div>
					<!-- 예금주 -->
					<div class="group row col1 half">
						<div class="group-inp" :class="companyInfoValidClass.refundAccountOwnerName">
							<label for="" class="label"> 예금주 </label>
							<input
								id="refundAccountOwnerName"
								type="text"
								v-model="companyInfo.taxInvoiceManager.refundAccountOwnerName"
								placeholder="예금주를 입력해주세요."
								@input="validateAccountOwnerName"
							/>
						</div>
					</div>
				</div>
			</div>
			<div class="section-wrap">
				<div class="btn-area">
					<slot name="saveCustomer" :fetchRegisterCustomer="fetchRegisterCustomer"></slot>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import Attach from '@/components/attach/Attach.vue';
import { validation } from '@/components/apply/mixinsValidation.js';
import mixinsErrCodeObserver from '@/components/exception/mixinsErrCodeObserver';
import CommonAlert from '@/components/common/CommonAlert.vue';
import { businessLicenseNumFailMessage, DEFAULT_MODAL_STYLE } from '@/utils/constants';
import _ from 'lodash';
import { DUPLICATE_BUSINESSLICENSENUM } from '@/temlplates';

export default {
	mixins: [validation, mixinsErrCodeObserver],
	components: { Attach },
	props: ['companyInfoProps', 'hasCompanyProperty'],
	data() {
		return {
			radioItems: [
				{ key: '00', name: '기업', value: 'CORPORATION' },
				{ key: '01', name: '개인', value: 'INDIVIDUAL' },
			],
			isConfirmedLicense: '',
			inputBusinessLicenseNum: '',
			customerType: 'ERP',
			managerList: [],
			companyInfo: { ...this.companyInfoProps },
			companyInfoValidClass: {
				// 필수값인 경우 invalid가 default, index 순서대로 표현
				corporateName: this.companyInfoProps.corporateName ? 'valid' : '',
				ceoName: this.companyInfoProps.ceoName ? 'valid' : '',
				repNumber: 'valid',
				businessLicenseNum: this.companyInfoProps.businessLicenseNum ? 'valid' : '',
				companyAddress: this.companyInfoProps.companyAddress ? 'valid' : '',
				businessNum: 'valid',
				industryCategory: 'valid',
				businessCondition: this.companyInfoProps.businessCondition ? 'valid' : '',
				businessType: this.companyInfoProps.businessType ? 'valid' : '',
				employeeCount: 'valid',
				taxInvoiceManagerName: this.companyInfoProps.taxInvoiceManager.taxInvoiceManagerName ? 'valid' : '',
				taxInvoiceEmail: this.companyInfoProps.taxInvoiceManager.taxInvoiceEmail ? 'valid' : '',
				refundAccountNumber: 'valid',
				bankName: 'valid',
				refundAccountOwnerName: 'valid',
				businessLicenses: this.companyInfoProps.businessLicenses ? 'valid' : '',
				checkedPrivacyPolicy: '',
			},
			errorMessage: {
				required: '입력 필수값입니다.',
			},
			checkedPrivacyPolicy: false,
			registeredBusinessNumber: '',
			originCompanyInfo: { ...this.companyInfoProps },
			isChange: false,
		};
	},
	watch: {
		'companyInfo.businessLicenses': 'monitorBusinessLicenses',
		'companyInfo.businessLicenseNum': 'monitorBusinessLicenseNum',
		checkedPrivacyPolicy: 'monitorCheckedPrivacyPolicy',
	},
	mounted() {
		this.companyInfo.corporationSize = this.companyInfo.corporationSize || this.radioItems[0].value;
		this.inputBusinessLicenseNum = this.companyInfo.businessLicenseNum;
		this.companyInfo.businessLicenseNum ? this.monitorBusinessLicenseNum(this.companyInfo.businessLicenseNum) : '';
		this.monitorCheckedPrivacyPolicy(this.hasCompanyProperty);
		this.checkedPrivacyPolicy = this.hasCompanyProperty;
		this.registeredBusinessNumber = this.hasCompanyProperty ? this.companyInfo.businessLicenseNum : '';
	},
	methods: {
		uploadedAttaches(values) {
			for (let value of values) {
				delete value.path;
			}
			this.companyInfo.businessLicenses = values;
		},
		async checkBusinessLicenseNumber() {
			if (this.isOriginBusinessLicenseNumber()) {
				this.$toasted.show('기존 사업자 등록번호와 동일합니다.');
				this.companyInfo.businessLicenseNum = this.inputBusinessLicenseNum;
				return;
			}

			if (this.isNotValidatedBusinessLicenseNumber()) {
				this.$toasted.show('사업자 등록번호의 형식에 맞지 않습니다.');
				return;
			}

			if (this.inputBusinessLicenseNum === '9999999999') {
				this.successCheckLicense();
				return;
			}

			await this.$store
				.dispatch('checkBusinessLicenseNumber', {
					businessLicenseNumber: this.inputBusinessLicenseNum.replace(/-/g, ''),
				})
				.then((res) => {
					res.data.available ? this.successCheckLicense() : this.failCheckLicense(res.data.message);
				})
				.catch((res) => this.observeErrCode(res.data.errorCode, res.data.errorMessage, this.$toasted.show));
		},
		resetBusinessLicenseNumber() {
			this.$toasted.show('사업자 등록번호를 재입력해주세요.');
			this.companyInfo.businessLicenseNum = '';
		},
		successCheckLicense() {
			this.$toasted.show('확인되었습니다.');
			this.companyInfo.businessLicenseNum = this.inputBusinessLicenseNum;
		},
		failCheckLicense(errMsg) {
			if (errMsg === businessLicenseNumFailMessage.DUPLICATE_NUM) {
				this.companyInvalidClass.businessLicenseNumReason = DUPLICATE_BUSINESSLICENSENUM;
				const message =
					'이미 존재하는 법인 정보입니다.\n' +
					'다른 사업자 등록 번호를 입력하시거나, 영업담당자에게 문의하여 초대장을 요청하세요.';
				this.$modal.show(
					CommonAlert,
					{
						title: '사업자 법인 정보 중복',
						contents: message,
						buttons: [
							{
								text: '확인',
								callback: () => {},
							},
						],
					},
					Object.assign({}, DEFAULT_MODAL_STYLE, { clickToClose: false }),
				);
			} else {
				this.$toasted.show(errMsg);
			}
		},
		isNotValidatedBusinessLicenseNumber() {
			return this.companyInfoValidClass.businessLicenseNum === 'invalid';
		},
		isOriginBusinessLicenseNumber() {
			return this.registeredBusinessNumber && this.registeredBusinessNumber === this.inputBusinessLicenseNum;
		},
		async fetchRegisterCustomer() {
			const params = {
				companyInfo: this.companyInfo,
				customerType: this.customerType,
				managerList: this.managerList,
			};

			if (this.isConfirmedLicense !== 'valid') {
				this.$toasted.show('사업자등록번호 확인이 필요합니다.');
				document.getElementById('businessLicenseNum').focus();
				return;
			}
			if (!this.checkValidInfos() || !this.checkedPrivacyPolicy) {
				this.$toasted.show('등록에 실패했습니다.');
				return;
			}
			this.trimInsideCompanyInfo(params.companyInfo);

			await this.$store
				.dispatch('saveCustomer', params)
				.then(async () => {
					this.$toasted.show('등록되었습니다.');
					let historyData = this.$store.getters.getCustomerHistoryInfo;
					await this.$store
						.dispatch('fetchCustomerHistoryInfo', {
							page: historyData.page,
							size: historyData.size,
						})
						.catch((res) => this.observeErrCode(res.data.errorCode, res.data.errorMessage, (e) => {}));
				})
				.catch((res) => this.observeErrCode(res.data.errorCode, res.data.errorMessage, this.$toasted.show));
		},
		checkValidInfos() {
			let isAllValid = true;
			let value = '';
			let isFirst = true;
			for (const info in this.companyInfoValidClass) {
				if (
					info === 'taxInvoiceEmail' ||
					info === 'taxInvoiceManagerName' ||
					info === 'refundAccountNumber' ||
					info === 'refundAccountOwnerName' ||
					info === 'bankName'
				) {
					value = this.companyInfo.taxInvoiceManager[info];
				} else {
					value = this.companyInfo[info];
				}
				if (!value || value.length === 0) {
					this.companyInfoValidClass[info] = this.companyInfoValidClass[info] || 'invalid';
				}
				isAllValid = isAllValid && this.companyInfoValidClass[info] === 'valid';
				if (!isAllValid && isFirst) {
					document.getElementById(info).focus();
					isFirst = false;
				}
			}
			return isAllValid;
		},
		trimInsideCompanyInfo(companyObj) {
			for (let [key, value] of Object.entries(companyObj)) {
				if (key === 'taxInvoiceManager') {
					this.trimInsideTaxInvoiceManager(value);
				} else if (key !== 'businessLicenses') {
					companyObj[key] = this.isTrimInsideTarget(key) ? this.trimInside(value) : value;
				}
			}
		},
		trimInsideTaxInvoiceManager(taxInvoiceManager) {
			for (let [key, value] of Object.entries(taxInvoiceManager)) {
				taxInvoiceManager[key] = this.trimInside(value);
			}
		},
		isTrimInsideTarget(key) {
			return (
				key !== 'corporateName' && key !== 'companyAddress' && key !== 'businessCondition' && key !== 'businessType'
			);
		},
		trimInside(value) {
			return value?.toString().replace(/\s/g, '');
		},
		trimAll(data) {
			if (!data) {
				return '';
			}
			return data.replaceAll(' ', '');
		},
		monitorBusinessLicenses(licenses, oldLicenses) {
			this.companyInfoValidClass.businessLicenses = licenses.length === 0 ? '' : 'valid';
		},
		monitorBusinessLicenseNum(licenseNum, oldLicenseNum) {
			this.isConfirmedLicense = licenseNum ? 'valid' : '';
		},
		monitorCheckedPrivacyPolicy(checked, oldChecked) {
			this.companyInfoValidClass.checkedPrivacyPolicy = checked ? 'valid' : '';
		},
		requiredMessage(data, message) {
			if (_.isEmpty(data.trim())) {
				return '입력 필수값입니다.';
			}
			return message;
		},
		// 상호명
		validateCorporateName(e) {
			this.companyInfoValidClass.corporateName =
				this.checkInputLengthTrim(e, 1, 64) && !_.isEmpty(e.target.value.trim()) ? 'valid' : 'invalid';
		},
		validateCeoName(e) {
			const ceoName = this.trimAll(e.target.value);
			this.companyInfo.ceoName = ceoName;
			this.companyInfoValidClass.ceoName =
				this.checkInputLengthTrim(e, 2, 100) && !_.isEmpty(ceoName) ? 'valid' : 'invalid';
		},
		validateRepNumber(e) {
			const repNumber = this.trimAll(e.target.value);
			this.companyInfo.repNumber = repNumber;
			this.companyInfoValidClass.repNumber =
				this.checkTelFormat(repNumber) && this.checkInputLengthTrim(e, 0, 40) ? 'valid' : 'invalid';
		},
		validateBusinessLicenseNumber(e) {
			const licenseNumber = this.trimAll(e.target.value);
			this.companyInfo.inputBusinessLicenseNum = licenseNumber;
			this.companyInfoValidClass.businessLicenseNum = this.checkBusinessNumber(licenseNumber) ? 'valid' : 'invalid';
		},
		//주소
		validateCompanyAddress(e) {
			this.companyInfoValidClass.companyAddress =
				this.checkInputLengthTrim(e, 2, 500) && !_.isEmpty(e.target.value.trim()) ? 'valid' : 'invalid';
		},
		validateBusinessNum(e) {
			const businessNum = this.trimAll(e.target.value);
			this.companyInfo.businessNum = businessNum;
			this.companyInfoValidClass.businessNum =
				this.checkInputLength(e, 0, 50) && this.checkInteger(businessNum) ? 'valid' : 'invalid';
		},
		//업태
		validateBusinessCondition(e) {
			this.companyInfoValidClass.businessCondition =
				this.checkInputLengthTrim(e, 1, 120) && !_.isEmpty(e.target.value.trim()) ? 'valid' : 'invalid';
		},
		//종목
		validateBusinessType(e) {
			this.companyInfoValidClass.businessType =
				this.checkInputLengthTrim(e, 1, 120) && !_.isEmpty(e.target.value.trim()) ? 'valid' : 'invalid';
		},
		validateEmployeeCount(e) {
			const employeeCount = this.trimAll(e.target.value);
			this.companyInfo.employeeCount = employeeCount;
			this.companyInfoValidClass.employeeCount = this.checkInteger(employeeCount) ? 'valid' : 'invalid';
		},
		validateTaxManagerName(e) {
			this.companyInfo.taxInvoiceManager.taxInvoiceManagerName = this.trimAll(e.target.value);
			this.companyInfoValidClass.taxInvoiceManagerName = this.checkInputLengthTrim(e, 1, 100) ? 'valid' : 'invalid';
		},
		validateTaxEmail(e) {
			const taxEmail = this.trimAll(e.target.value);
			this.companyInfo.taxInvoiceManager.taxInvoiceEmail = taxEmail;
			this.companyInfoValidClass.taxInvoiceEmail = this.checkEmail(taxEmail) ? 'valid' : 'invalid';
		},
		validateAccountNumber(e) {
			const accountNumber = this.trimAll(e.target.value);
			this.companyInfo.taxInvoiceManager.refundAccountNumber = accountNumber;
			this.companyInfoValidClass.refundAccountNumber = this.checkInteger(accountNumber) ? 'valid' : 'invalid';
		},
		validateBankName(e) {
			this.companyInfo.taxInvoiceManager.bankName = this.trimAll(e.target.value);
		},
		validateAccountOwnerName(e) {
			this.companyInfo.taxInvoiceManager.refundAccountOwnerName = this.trimAll(e.target.value);
		},
	},
};
</script>
<style lang=""></style>
