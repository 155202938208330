<template>
	<!-- contents area -->
	<div>
		<div class="container">
			<!-- contents area -->
			<div class="body-contents">
				<h2 class="heading-1-b">회사법인정보관리</h2>
				<CompanyInfoDetails v-if="isShowCustomerRegister" :companyInfoProps="companyInfo" :hasCompanyProperty="false">
					<template v-slot:saveCustomer="slotCustomerInfo">
						<button class="solid large" @click="slotCustomerInfo.fetchRegisterCustomer">등록</button>
					</template>
				</CompanyInfoDetails>
			</div>
		</div>
	</div>
</template>
<script>
import CompanyInfoDetails from '@/components/apply/serviceApplyInfo/CompanyInfoDetails';

export default {
	components: { CompanyInfoDetails },
	data() {
		return {
			isShowCustomerRegister: false,
			companyInfo: {
				companyName: '',
				ceoName: '',
				repNumber: '',
				businessLicenseNum: '',
				companyAddress: '',
				businessLicenses: [],
				industryCategory: '',
				businessNum: '',
				businessCondition: '',
				corporateName: '',
				businessType: '',
				confirm: true,
				corporationSize: '',
				taxInvoiceManager: {
					taxInvoiceEmail: '',
					taxInvoiceManagerName: '',
					refundAccountNumber: '',
					refundAccountOwnerName: '',
					bankName: '',
				},
				employeeCount: '',
			},
		};
	},
	methods: {
		goCustomerRegister() {
			this.isShowCustomerRegister = true;
		},
	},
};
</script>
<style lang=""></style>
