<template>
	<div>
		<div v-if="hasCompanyProperty">
			<CustomerInfoDetails :companyInfoProps="$store.getters.getCompanyInfo" :hasCompanyProperty="hasCompanyProperty">
				<template v-slot:saveCustomer="slotCustomerInfo">
					<button class="solid large" @click="slotCustomerInfo.fetchRegisterCustomer">저장</button>
				</template>
			</CustomerInfoDetails>
			<CustomerHistory :customerHistoryInfo="$store.getters.getCustomerHistoryInfo"></CustomerHistory>
		</div>
		<div v-else>
			<CustomerInfoConnect></CustomerInfoConnect>
		</div>
	</div>
</template>

<script>
import CustomerInfoConnect from '@/components/user/CustomerInfoConnect';
import CustomerInfoDetails from '@/components/apply/serviceApplyInfo/CompanyInfoDetails';
import CustomerHistory from '@/components/apply/serviceApplyInfo/CustomerHistory';
import mixinsErrCodeObserver from '@/components/exception/mixinsErrCodeObserver';
import _ from 'lodash';

export default {
	components: { CustomerInfoConnect, CustomerInfoDetails, CustomerHistory },
	created() {
		this.searchCompanyInfo();
	},
	mixins: [mixinsErrCodeObserver],
	computed: {
		hasCompanyProperty() {
			if (_.isEmpty(this.$store.getters.getCompanyInfo)) {
				return false;
			}
			return true;
		},
	},
	methods: {
		async searchCompanyInfo() {
			await this.$store
				.dispatch('fetchCustomerInfo')
				.catch((res) => this.observeErrCode(res.data.errorCode, res.data.errorMessage, (e) => {}));

			let historyData = this.$store.getters.getCustomerHistoryInfo;
			await this.$store
				.dispatch('fetchCustomerHistoryInfo', {
					page: historyData.page,
					size: historyData.size,
				})
				.catch((res) => this.observeErrCode(res.data.errorCode, res.data.errorMessage, (e) => {}));
		},
	},
};
</script>

<style scoped>
.edit_position {
	vertical-align: 2px;
	margin-left: 5px;
	cursor: pointer;
}
</style>
