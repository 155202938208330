<template>
	<div class="container">
		<!-- contents area -->
		<div class="body-contents history">
			<div class="section-wrap gap64">
				<div class="section">
					<h3 class="title">히스토리</h3>
					<div class="contents timeline">
						<div class="group column">
							<div class="group row" v-for="(history, index) in customerHistoryInfo.historyList" :key="index">
								<div class="cell date">
									<span class="txt">{{ history.createdAt }}</span>
								</div>
								<div class="cell name">
									<span class="txt">{{ history.username }}</span>
								</div>
								<div class="cell state">
									<span class="tag">{{ history.historyType }}</span>
								</div>
							</div>
						</div>
					</div>

					<!-- pagenation -->
					<div class="pagination-group">
						<!--wrap_paging-->
						<BasePagination
							:perPage="parseInt(customerHistoryInfo.size)"
							:total="customerHistoryInfo.total"
							@page="getHistoryInfoByCurrentPage"
						></BasePagination>
						<div class="select" v-if="windowWidth > 720">
							<select v-model="customerHistoryInfo.size" @change="getHistoryInfoByChangedPageSize">
								<option selected="selected">10</option>
								<option>30</option>
								<option>50</option>
							</select>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import BasePagination from '@/components/common/BasePagination';
import mixinsErrCodeObserver from '@/components/exception/mixinsErrCodeObserver';
export default {
	components: { BasePagination },
	props: ['customerHistoryInfo'],
	data() {
		return {
			windowWidth: window.innerWidth,
		};
	},
	mixins: [mixinsErrCodeObserver],
	mounted() {
		this.$nextTick(() => {
			window.addEventListener('resize', this.onResize);
		});
	},
	methods: {
		async getHistoryInfoByCurrentPage(pageInfo) {
			await this.$store
				.dispatch('fetchCustomerHistoryInfo', {
					page: pageInfo.currentPage - 1,
					size: pageInfo.currentPerpage,
				})
				.catch((res) => this.observeErrCode(res.data.errorCode, res.data.errorMessage, this.$toasted.show));
		},
		async getHistoryInfoByChangedPageSize() {
			await this.$store
				.dispatch('fetchCustomerHistoryInfo', {
					page: 0,
					size: this.customerHistoryInfo.size,
				})
				.catch((res) => this.observeErrCode(res.data.errorCode, res.data.errorMessage, this.$toasted.show));
		},
		onResize() {
			this.windowWidth = window.innerWidth;
		},
	},
};
</script>
<style scoped></style>
